/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import discordLogo from "../../assets/icons8-discord.svg";
// import twitterLogo from "../../assets/icons8-twitter.svg";
// import heroImage from "../../assets/SolanaSanta-bodies.gif";

type Props = {
    title: string,
    description: string,
    image: string,
    imageDesc: string
}

export const Hero = ({title, description, image, imageDesc}: Props) => {
    return (
        <div className="container my-5 hero">
            <div className="row align-items-center rounded-3 border shadow-lg">
                <div className="col-lg-7 p-3 p-lg-5">
                    <h1 className="display-4 fw-bold lh-1">{title}</h1>
                    <p className="lead">{description}</p>
                </div>
                <div className="col-lg-5 p-0 overflow-hidden">
                    <img className="rounded-lg-3" src={image} alt={imageDesc} width="600"/>
                </div>
            </div>
        </div>
    );
};