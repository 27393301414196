/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
export const Footer = () => {
    return (
        <footer className="py-5">
            <div className="container">
                <p className="text-end">
                    <a href="#">Back to top</a>
                </p>
                <p>
                    Made with &hearts; by the SolPhantoms team.
                </p>
            </div>
        </footer>
    );
};