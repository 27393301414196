/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Container, Image, Row, Col, Stack} from "react-bootstrap";
import {Parallax, ParallaxLayer} from '@react-spring/parallax';

import Elf_Sil from "../../assets/elf/elf-silhouette-gradient.svg";

import "./roadmap.scss";

export const Roadmap = () => {

    return (
        <>
            <Container>
                <h2 className="text-center">Roadmap</h2>
            </Container>

            <Parallax pages={7} style={{position: 'relative', height: '400px'}}>
                <ParallaxLayer
                    // sticky={{start: 0, end: 1}}
                    offset={0}
                    speed={1.4}
                    style={{...Roadmap, justifyContent: 'flex-start'}}
                >
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station One</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>Launching the website</li>
                                    <li>Launching Twitter & Discord</li>
                                    <li>Announcing 10% NGO donation towards Jusoor.ngo</li>
                                    <li>Airdropping 7 Elves after the main mint to our community, competition 1</li>
                                    <li>Airdropping 7 Elves after the main mint to our community, competition 2</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 1, end: 2}}
                    offset={1}
                    speed={1.4}
                    style={{...Roadmap, justifyContent: 'flex-start'}}
                >
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Two</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>Pre-launching sale of 700 Elves discounted at 50% (70 mins)</li>
                                    <li>Launching 88% of the NFT collection to mint</li>
                                    <li>Choosing 7 random participants of the initial mint and will airdrop one Elf for
                                        each.
                                    </li>
                                    <li>Listing verified collection on marketplaces (more details to be announced)</li>
                                    <li>Airdropping 7 Elves to our community, competition 3</li>
                                    <li>Airdropping 7 Elves to our community, competition 4</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 2, end: 3}}
                    offset={2.5}
                    style={{...Roadmap, justifyContent: 'flex-start'}}
                >
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Three</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>Unwrapping the collection rarity</li>
                                    <li>Revealing the second part of SolPhantoms Christmas project</li>
                                    <li>Airdropping 7 Elves after the main mint to our community, giveaways</li>
                                    <li>Airdropping 7 Elves to our community, competition, etc</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 3, end: 4}}
                    offset={2.5}
                    style={{...Roadmap, justifyContent: 'flex-start'}}>
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Four</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>Validating wallets for Elf holders on Discord to gain access to exclusive
                                        channels
                                        and
                                        other exciting perks/games that Santa will tell you about!
                                    </li>
                                    <li>Airdropping 7 Elves to our community, giveaways</li>
                                    <li>Airdropping 7 Elves to our community, competition, etc</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 4, end: 5}}
                    offset={2.5}
                    style={{...Roadmap, justifyContent: 'flex-start'}}>
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Five</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>Q and A with the community for a brainstorming session for projects they are
                                        interested
                                        in
                                    </li>
                                    <li>Airdropping 7 Elves to our community, competition, etc</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 5, end: 6}}
                    offset={2.5}
                    style={{...Roadmap, justifyContent: 'flex-start'}}>
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Six</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>If all the Elves found a new home, they will bring you extra surprises during
                                        the
                                        first
                                        few months of next year!!
                                    </li>
                                    <li>
                                        <ul>
                                            <li>Launching a collection of 777 3D Elves</li>
                                            <li>Giving away 77 and a special gift from the Elves</li>
                                            <li>Choosing the most 7 friends who interacted with us on Twitter and
                                                Discord,
                                                to
                                                turn their picture into a customized Elf NFT
                                            </li>
                                        </ul>
                                    </li>
                                    <li>Airdropping 7 Elves to our community, competition, etc</li>
                                    <li>Airdropping 7 Elves to our community, giveaways</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>

                <ParallaxLayer
                    // sticky={{start: 6, end: 7}}
                    offset={2.5}
                    style={{...Roadmap, justifyContent: 'flex-start'}}>
                    <Container fluid className="roadmap-station">
                        <Row className="justify-content-center">
                            <Col md={{span: 8, offset: 2}}>
                                <h4>Station Seven</h4>
                            </Col>
                            <Col md={5} className="p-2 my-2">
                                <ul>
                                    <li>The Elves will let you in on a little secret, shhhhh!</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </ParallaxLayer>
            </Parallax>

        </>
    );
};