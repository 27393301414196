/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Card} from "../card/card";
import SolanaSanta from "../../assets/projects/banner 16-9 Santa-01.png";
import ElfNFT from "../../assets/projects/banner 16-9 elfs-01.png";
// import "./projects.scss";
export const Projects = () => {
    return (
        <div id="projects" className="container">
            <h2 className="pt-5 text-center">Upcoming Projects</h2>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 g-3 mt-1 p-5">
                <Card title="Elf NFT"
                      className=""
                      description="Santa can’t do his job without his little helpers. The Elf is a milestone for
                           SolPhantoms, as the first NFT collection enabling us to send out gifts that change the world."
                      image={ElfNFT}
                      website="https://elfnft.com"
                      twitter="https://twitter.com/ElfNFT"
                      discord="#" date="14 Dec 2021"></Card>
                <Card title="SantaSolana"
                      className="coming-soon"
                      description="addsfadsfdsaf"
                      image={SolanaSanta}
                      website="https://solanasanta.io"
                      twitter="https://twitter.com/SolanaSanta"
                      discord="#" date="Launch: 14 Dec 2021"></Card>
            </div>
        </div>
    );
};