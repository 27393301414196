import React from 'react';
// import {Grid, Row, Col} from 'react-bootstrap';
// import { Jumbotron } from './jumbotron';
import {Hero} from '../../components/hero/hero';
// import { Card } from './card';
import {Faq} from '../../components/faq/faq';
import {Projects} from "../../components/projects/projects";
import {Team} from "../../components/team/team";

import heroImage from "../../assets/sol-hero.gif";
import {AboutElf} from "../../components/elf/about/about";

export const Main = () => {
    return (
        <main role="main">
            <Hero title="We are SolPhantoms" image={heroImage} imageDesc="" description="Unified around art, Solana and
                spreading good deeds, we are a global team of
                talented individuals across five countries collaborating on diverse sets of NFT projects. Our
                digital art is open for creative thinking or use of new methods like illustrations, 3D modeling
                and use of animation."/>
            <Projects/>
            {/*<AboutElf/>*/}
            <Team/>
            {/*<Faq/>*/}
        </main>
    )
        ;
};