/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
// import {Team} from "../../components/elf/team/team";
import {Roadmap} from "../../components/elf/roadmap/roadmap";
import {Gallery} from "../../components/elf/gallery/gallery";
import {Faq} from "../../components/elf/faq/faq";
import {AboutElf} from "../../components/elf/about/about";
// import {Footer} from "../../components/elf/footer";

// import "./style.scss";

export const ElfNFT = () => {

    return (
        <div className="elf-page">

            <AboutElf/>
            <Gallery/>
            <Roadmap/>
            <Faq/>
        </div>
    );
};