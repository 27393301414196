import React from "react";
import {Button, Container, Image, Row, Col} from "react-bootstrap";
import FingerScroll from "../../../assets/horizontal-scroll.png";
import Elf01 from "../../../assets/projects/elf/elf-01.jpg";
import Elf02 from "../../../assets/projects/elf/elf-02.jpg";
import Elf03 from "../../../assets/projects/elf/elf-03.jpg";
import Elf04 from "../../../assets/projects/elf/elf-04.jpg";
import Elf05 from "../../../assets/projects/elf/elf-05.jpg";

import {Buy} from "../mint/buy";

import "./gallery.scss";

export const Gallery = () => {
    return (
        <>
            <Container fluid className="gallery-container d-none">
                <Buy/>
                <Row className="text-center d-flex justify-content-center mb-5">
                    <Col sm={4}>
                        {/*<Button variant="outline-warning" size="lg" className="cta-btn text-center py-4 px-2" href="#mint">Get your elf</Button>*/}

                    </Col>
                </Row>
                <div className="scroll-downs">
                    <div className="mousey d-none d-md-block">
                        <div className="scroller"></div>
                    </div>
                    <Image className="pointer d-md-none" src={FingerScroll} alt=""/>
                </div>
                <div className="gallery scrolling-wrapper">

                    <div className="card gallery-item"><Image className="art-display" src={Elf01} alt=""/></div>
                    <div className="card gallery-item"><Image className="art-display" src={Elf02} alt=""/></div>
                    <div className="card gallery-item"><Image className="art-display" src={Elf03} alt=""/></div>
                    <div className="card gallery-item"><Image className="art-display" src={Elf04} alt=""/></div>
                    <div className="card gallery-item"><Image className="art-display" src={Elf05} alt=""/></div>
                </div>
            </Container>
            <Container fluid className="photobanner-container">
                <div className="photobanner">
                    <Image className="photobanner-item" src={Elf01} alt=""/>
                    <Image className="photobanner-item" src={Elf02} alt=""/>
                    <Image className="photobanner-item" src={Elf03} alt=""/>
                    <Image className="photobanner-item" src={Elf04} alt=""/>
                    <Image className="photobanner-item" src={Elf05} alt=""/>
                </div>
            </Container>
        </>
    );
};