/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Accordion } from "react-bootstrap";
import "./faq.scss";
export const Faq = () => {
    return (
        <div id="faq" className="faq container mb-5">
            <h2 className="py-5 text-center">Frequently Asked Questions</h2>
            <div className="row justify-content-center">
                {/*<Accordion className="col-md-9" defaultActiveKey="0" flush>*/}
                <Accordion className="col-md-9" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>What is an Elf?</Accordion.Header>
                        <Accordion.Body>
                            Elves are Santa’s little helpers at the Christmas Workshop. There are 7777 Elves, each one
                            is unique in their own way (like you and I are). There are 8 distinctive features (attributes)
                            and over 150 different looks (attributes values) to create distinctive characters.
                            The little helpers are the first step of our Christmas project
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>How many Elves are out there?</Accordion.Header>
                        <Accordion.Body>
                            There are 7777 Elves looking for a home.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>The cost of an Elf...</Accordion.Header>
                        <Accordion.Body>
                            At minting, every Elf will be yours for (you guessed it) 0.7777 SOL
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>When can we start adopting the Elves?</Accordion.Header>
                        <Accordion.Body>
                            The official launch is on 5 December at 15:07 UTC, 16:07 CET,
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How can I adopt an Elf (how to mint)?</Accordion.Header>
                        <Accordion.Body>
                            <ol>
                                <li>Make sure you have at least 0.8 SOL in your wallet (if you only want to mint 1)</li>
                                <li>Go to our website by the time of the launch (5 December at 15:07 UTC, 16:07 CET)</li>
                                <li>Connect your wallet</li>
                                <li>Adopt your Elf :)</li>
                            </ol>
                            <p>(P.S: The Elves suggest Phantom Wallet - http://phantom.app)</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Are you befriending any rarity application?</Accordion.Header>
                        <Accordion.Body>
                            We've sent a few friend requests. Hint: howrare.is your Elf?
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>I missed the drop date. Where can I get myself an elf?</Accordion.Header>
                        <Accordion.Body>
                            We are in the process to list and verify our Elf NFT collection with a few household Solana marketplaces.
                            We will make an announcements once we are listed.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};
