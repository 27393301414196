/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import discordSVG from '../../assets/icons8-discord.svg';
import twitterSVG from '../../assets/icons8-twitter.svg';

type Props = {
    title: string,
    description: string,
    image: string,
    website: string,
    twitter: string,
    discord: string,
    date: string,
    className: string
}
export const Card = ({title, description, image, website, twitter, discord, date, className}: Props) => {
    return (
        <div className="col">
            <div className={`card project-card shadow-sm ${className}`}>
                <img src={image} alt={title} className="bd-placeholder-img card-img-top" width="100%"/>

                <div className="card-body px-5 pb-5">
                    <h4>{title}</h4>
                    <small className="project-card-drop">{date}</small>
                    <p className="card-text">{description}</p>
                    <div className="col-8 col-md-4">
                        <div className="btn-group">
                            <a href={website} target="_blank" aria-current="page"
                               className="btn btn-md btn-outline-light">Website</a>
                            <a href={twitter} target="_blank" aria-current="page"
                               className="btn btn-md btn-outline-light">Twitter</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};