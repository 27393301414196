import React from "react";
import {Figure} from "react-bootstrap";

import chief from "../../assets/team/team_chief_avatar-01.png";
import lost from "../../assets/team/team_lost_avatar-01.png";
import messy from "../../assets/team/team_messy_avatar-01.png";
import chaotic from "../../assets/team/team_chaotic_avatar-01.png";
import creative from "../../assets/team/team_creative_avatar-01.png";
import unstructured from "../../assets/team/team_unstructured_avatar-01.png";

const avatars = [
    {
        name: 'Chief Phantom',
        pic: chief,
        bio: 'a',
        twitter: ''
    },
    {
        name: 'Lost Phantom',
        pic: lost,
        bio: '',
        twitter: ''
    },
    {
        name: 'Messy Phantom',
        pic: messy,
        bio: '',
        twitter: ''
    },
    {
        name: 'Chaotic Phantom',
        pic: chaotic,
        bio: '',
        twitter: ''
    },
    {
        name: 'Creative Phantom',
        pic: creative,
        bio: '',
        twitter: ''
    },
    {
        name: '(Un)structured Phantom',
        pic: unstructured,
        bio: '',
        twitter: ''
    }
]

const members = avatars.map((avatar) =>
    <Figure className="p-5 justify-content-center text-center">
        <Figure.Image
            width={171}
            height={180}
            alt="171x180"
            src={avatar.pic}
        />
        <Figure.Caption>
            {avatar.name}
        </Figure.Caption>
    </Figure>
);

export const ImageGrid = () => {
    return (
        <>{members}</>
    );
};
