import React from "react";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import ElfNFTBanner from "../../assets/elf/elfnft-banner.png";
import ElfNFTanimated from "../../assets/elf/Elf-red-BG.gif";
import {Buy} from "../mint/buy";
import "./about.scss";

import ElfBanner01 from "../../../assets/projects/elf/banner/wallpaper-Elf-01.png";
import ElfBanner02 from "../../../assets/projects/elf/banner/wallpaper-Elf-02.png";
import ElfBanner03 from "../../../assets/projects/elf/banner/wallpaper-Elf-03.png";
import ElfBanner04 from "../../../assets/projects/elf/banner/wallpaper-Elf-04.png";

import ElfPremint from "../../../assets/projects/elf/elf-premint.png";

// TODO: Add # ID attribute for About page nav

export const AboutElf = () => {
    const fields = [];
    for (let i = 1; i <= 32; i++) {
        fields.push(
            // <div className="circle-container">
            //     <div className="circle"></div>
            // </div>
        );
    }
    return (
        <>
            <Container fluid className="raining">
                <Row className="elf-banner justify-content-center">
                    <Col md={8}>
                        <h1 id="about" className="px-5 pt-0 pb-5 text-center new-font mt-2">All I want for Christmas is
                            one of the 7777 unique elves.</h1>
                    </Col>

                    <Image src={ElfBanner01} className="elf-banner-layer animate-01"/>
                    <Image src={ElfBanner02} className="elf-banner-layer animate-02"/>
                    <Image src={ElfBanner03} className="elf-banner-layer animate-03"/>
                    <Image src={ElfBanner04} className="elf-banner-layer animate-04"/>
                </Row>
                <Container fluid className="elf-banner-content rounded-3">
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <br className="my-2"/>
                            <h2 className="new-font" mb-3>Why an Elf?</h2>
                            <p>Because Santa can’t do his job without his little helpers.</p>
                            <p>This Christmas we (SolPhantoms) would like to spread joy and happiness to people a little
                                differently. As this year the elves won’t be boxing toys and tangible gifts. However,
                                they
                                will be busy packing something else, they will be filling the gift boxes with hope for
                                the
                                people. The kind of hope that builds bridges for a better tomorrow. The hovering Sol
                                Phantoms wanted to have Jusoor as their first friend in this journey.
                            </p>
                            <br className="my-2"/>
                            <h2 className="new-font" my-3>How the ElfNFT supports education?</h2>
                            <p>
                                Jusoor was born in the first year of the Syrian crisis (2011), with a global mission to
                                facilitate quality education for young Syrians around the world and teach them the
                                know-how
                                to be great individuals.
                            </p>
                            <p>
                                These are the types of gifts that give real hope to the people who need it most, gifts
                                that
                                change the world.
                            </p>
                        </Col>
                    </Row>
                </Container>
                {fields}
            </Container>

            <Container fluid className="mint-section">
                <Row className="py-3">
                    <Col md={{span: 5, offset: 1}} className="text-center">
                        <Image fluid src={ElfPremint} width="450"/>
                    </Col>
                    <Col md={4} className="justify-content-center text-center">
                        <br className="my-5"/>
                        <h2>Mint your elf</h2>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially
                            unchanged. </p>
                        {/*<Button variant="secondary" className="disabled rounded-pill">Mint not started</Button>*/}
                        <Buy/>

                    </Col>
                </Row>

            </Container>

        </>
    );
};