/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Container, Row, Col, Stack, Image, Card, Button} from "react-bootstrap";

import chief from "../../assets/team/team_chief_avatar-01.png";
import lost from "../../assets/team/team_lost_avatar-01.png";
import messy from "../../assets/team/team_messy_avatar-01.png";
import chaotic from "../../assets/team/team_chaotic_avatar-01.png";
import creative from "../../assets/team/team_creative_avatar-01.png";
import unstructured from "../../assets/team/team_unstructured_avatar-01.png";
import "./team.scss";

import {ImageGrid} from "./ImageGrid";

type Props = {
    image: string,
    name: string,
    bio: string,
    twitter: string
}

export const TeamMember = ({image, name, bio, twitter}: Props) => {
    return (
        <Card className="py-4 mb-5 mx-4">
            <Card.Img variant="top" src={image}/>
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>
                    {bio}
                </Card.Text>
                <Button href={twitter} variant="primary">Twitter</Button>
            </Card.Body>
        </Card>
    );

};

export const Team = () => {
    // @ts-ignore
    return (
        <Container fluid className="team justify-content-center">
            <div className="stars"></div>
            <div className="twinkling"></div>
            <div className="clouds"></div>
            <div id="team" className="container team">
                <h2 className="py-5 text-center">Team</h2>
            </div>
            <Container className="justify-content-center text-center position-relative">
                <ImageGrid/>
            </Container>

        </Container>
    );
};